const BASE_URL = (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_API_BASEURL_LOCAL : process.env.VUE_APP_API_BASEURL_SERVER);

export function getImageUrl(image) {
  const { hasFile } = image;
  if (hasFile) {
    const { hashName, path } = hasFile;
    return `${BASE_URL}${path}${hashName}`;
  } else if ("hashName" in image) {
    const { hashName, path } = image;
    return `${BASE_URL}${path}${hashName}`;
  }
  return "";
}

export function getPortfolioImages(data) {
  const { hasPortfolioInnerFiles, hasPortfolioOtherFiles } = data;
  const newImages = [];

  if (hasPortfolioInnerFiles) {
    hasPortfolioInnerFiles.forEach((image) => {
      const newImage = getImageUrl(image);
      if (newImage) {
        newImages.push(newImage);
      }
    });
  }

  if (hasPortfolioOtherFiles) {
    hasPortfolioOtherFiles.forEach((image) => {
      const newImage = getImageUrl(image);
      if (newImage) {
        newImages.push(newImage);
      }
    });
  }

  return newImages;
}

export function getInteriorImages(data) {
  const { hasThumbnail, hasInteriorOfficeFiles } = data;
  const newImages = [];

  if (hasThumbnail) {
    const newImage = getImageUrl(hasThumbnail);
    if (newImage) {
      newImages.push(newImage);
    }
  }

  if (hasInteriorOfficeFiles) {
    hasInteriorOfficeFiles.forEach((image) => {
      const newImage = getImageUrl(image);
      if (newImage) {
        newImages.push(newImage);
      }
    });
  }

  return newImages;
}

export function getInteriorOfferImages(data) {
  const { hasInteriorOfferInnerFiles, hasInteriorOfferOuterFiles } = data;
  const newImages = [];

  if (hasInteriorOfferInnerFiles) {
    hasInteriorOfferInnerFiles.forEach((image) => {
      const newImage = getImageUrl(image);
      if (newImage) {
        newImages.push(newImage);
      }
    });
  }

  if (hasInteriorOfferOuterFiles) {
    hasInteriorOfferOuterFiles.forEach((image) => {
      const newImage = getImageUrl(image);
      if (newImage) {
        newImages.push(newImage);
      }
    });
  }

  return newImages;
}

export function getPlaceImages(data) {
  const { hasPlaceInnerFiles, hasPlaceOtherFiles, hasPlaceOuterFiles } = data;
  const newImages = [];

  if (hasPlaceOuterFiles) {
    hasPlaceOuterFiles.forEach((image) => {
      const newImage = getImageUrl(image);
      if (newImage) {
        newImages.push(newImage);
      }
    });
  }

  if (hasPlaceInnerFiles) {
    hasPlaceInnerFiles.forEach((image) => {
      const newImage = getImageUrl(image);
      if (newImage) {
        newImages.push(newImage);
      }
    });
  }

  if (hasPlaceOtherFiles) {
    hasPlaceOtherFiles.forEach((image) => {
      const newImage = getImageUrl(image);
      if (newImage) {
        newImages.push(newImage);
      }
    });
  }

  return newImages;
}
