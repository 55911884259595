<template>
  <Layout :title="'후기작성'">
    <div class="flex flex-col container items-center">
      <div
        class="w-full container mt-4 flex flex-row items-center justify-center"
      >
        <div
          class="image"
          :style="{
            backgroundImage: image ? `url(${image})` : '',
          }"
        />

        <div></div>
      </div>

      <hr />

      <div class="review-wrapper flex flex-col mb-16 mt-4">
        <div class="review-header flex flex-col items-center mb-8 mt-8">
          <h3 class="mb-3">{{ office.title }} 님 과의 거래가 어떠셨나요?</h3>

          <StarRating
            :show-rating="false"
            :star-size="40"
            :padding="10"
            :active-color="'#293bf0'"
            v-model="rating"
          />

          <h5 class="mt-3">별점 선택 부탁드려요!</h5>
        </div>

        <textarea
          v-model="content"
          class="mb-3"
          placeholder="후기를 입력해주세요"
        />

        <FileInput :multiple="true" :onChange="onFilesChange" />

        <button @click="onSubmit" class="mt-8">후기 등록</button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import FileInput from "@/components/FileInput.vue";
import StarRating from "vue-star-rating";
import services from "@/services";
import utils from "@/utils";

export default {
  name: "InteriorReviewWrite",
  components: {
    Layout,
    FileInput,
    StarRating,
  },
  data() {
    return {
      rating: 0,
      content: "",
      files: [],
      data: {},
    };
  },
  mounted() {
    const { id } = this.$route.params;
    this.getData(id);
  },
  methods: {
    onFilesChange(value) {
      this.files = value;
    },
    async getData(id) {
      const data = await services.interiorJoinOfferDetail(id);
      this.data = data;
    },
    async onSubmit() {
      const { id } = this.$route.params;
      const payload = {
        interiorJoinOfferId: id,
        rating: this.rating,
        contents: this.content,
        files: this.files,
      };
      const success = await services.reviewCreate(payload);
      if (success) {
        this.$toast.open("리뷰작성 완료");
        this.$router.go(-1);
      } else {
        this.$toast.open({ messagE: "리뷰작성 실패", type: "error" });
      }
    },
  },
  computed: {
    name() {
      if (this.data) {
        const { hasPlace } = this.data;

        if (hasPlace) {
          const { hasOwner } = hasPlace;
          return hasOwner.nickname;
        }
      }

      return "";
    },
    office() {
      if (this.data) {
        const { hasInteriorOffice } = this.data;
        if (hasInteriorOffice) {
          return hasInteriorOffice;
        }
      }
      return { title: "" };
    },
    image() {
      if (this.office) {
        const { hasThumbnail } = this.office;
        if (hasThumbnail) {
          return utils.getImageUrl(hasThumbnail);
        }
      }

      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.review-header {
  h3 {
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.84px;
    text-align: center;
    color: #000;
  }

  h5 {
    font-size: 19px;
    font-weight: normal;
    letter-spacing: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    text-align: center;
    color: #000;
  }
}

.image {
  width: 192px;
  height: 137px;
  background-color: #ddd;
  border-radius: 10px;
  margin-right: 23px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.review-wrapper {
  width: 444px;

  textarea {
    height: 228px;
    resize: none;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.14px;
    color: #222;
    border: 1px solid #c7c7c7;
    padding: 25px;
  }

  button {
    height: 40px;
    background-color: #283aef;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.56px;
    text-align: center;
    color: #fff;
  }
}

@media (max-width: 639px) {
  .review-wrapper {
    max-width: 444px;
    width: 100%;
  }
  .review-header {
    h3 {
      font-size: 18px;
    }
  }
}
</style>
