<template>
  <div class="file-input">
    <label v-if="hasLabel">{{ label }} <span>*</span></label>
    
    <div @click="trigger" class="w-full flex justify-center items-center file-content">
      <span>첨부파일 업로드 +</span>
    </div>

    <div v-if="!multiple && file !== null" class="file-result flex flex-row items-center">
      <div @click="onRemove" class="close flex justify-center items-center">x</div>

      <span>{{ file.name }}</span>
    </div>

    <div v-if="multiple && files.length > 0">
      <div v-for="(item, index) in files" :key="index" class="file-result flex flex-row items-center">
        <div @click="() => onRemoveFile(files, index)" class="close flex justify-center items-center">
          <eva-icon name="close-outline" :width="10" :height="10" :fill="'#fff'" />
        </div>

        <span>{{ item.name }}</span>
      </div>
    </div>
    
    <input :multiple="multiple"  ref="fileInput" class="hidden" type="file" @change="onFileChange" />
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FileInput',
  props: {
    label: {
      type: String,
      default: '첨부파일'
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    onChange: Function,
  },
  data() {
    return {
      file: null,
      files: [],
    }
  },
  methods: {
    trigger: function() {
      const inputEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
      });
      this.$refs.fileInput.dispatchEvent(inputEvent);
    },
    onFileChange: function(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        if (this.multiple) {
          this.files = _.concat(this.files, [...files]);
          this.onChange(this.files);
        } else {
          this.file = files[0];
          this.onChange(this.file);
        }
      }
    },
    onRemove: function() {
      this.file = null;
      this.$refs.fileInput.value = null;
    },
    onRemoveFile(files, index) {
      this.files = files.filter((f, i) => i !== index);
      this.$refs.fileInput.value = null;
    }
  },
  watch: {
    label() {
      this.$refs.fileInput.value = null;
      this.file = null;
      this.files = [];
    },
  }
}
</script>

<style lang="scss" scoped>
.file-input {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.56px;
  text-align: left;
  color: #555;
  margin-bottom: 10px;

  span {
    color: #f20000;
  }

  .file-content {
    height: 40px;
    background-color: #f6f6f6;
    cursor: pointer;
    margin-top: 13px;

    span {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      text-align: left;
      color: #828282;
    }
  }

  .file-result {
    margin-top: 17px;

    .close {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #5e5e5e;
      margin-right: 6px;
      cursor: pointer;

      color: white;
      font-size: 10px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      text-align: left;
      color: #bababa;
    }
  }
}
</style>