import { chunk } from "./array";
import {
  getImageUrl,
  getPlaceImages,
  getInteriorImages,
  getInteriorOfferImages,
  getPortfolioImages,
} from "./image";

export default {
  chunk,
  getImageUrl,
  getPlaceImages,
  getInteriorImages,
  getInteriorOfferImages,
  getPortfolioImages,
};
